









































import { Component, Vue } from "vue-property-decorator";
import { interfaceService } from "@/services/Interface";

@Component
export default class AboutSection extends Vue {
  //
  mounted() {
    // ok
  }

  toValues() {
    interfaceService.toHomeSection("values");
  }
}
