import QueryString from "qs"
import { StrapiEntity, StrapiImage, Res } from "./strapi.common"
import { API_URL } from "@/gloabls"

export interface WBCustomer extends StrapiEntity {
    company_name: string,
    show_in_trusted_us: string
}

export interface WBCategory extends StrapiEntity {
    name: string
    order: number
}

export interface WBProjectList extends StrapiEntity {
    name: string
    slug: string
    locale: string,
    short_description: string
    description: string
    page_url?: string
    realization_date: string
    thumbnail_image: StrapiImage
    customer: WBCustomer
    categories?: WBCategory[]
    gallery?: StrapiImage[]
}

export interface WBTeam extends StrapiEntity {
    profile_image: StrapiImage
    full_name: string
    title: string
    linkedIn_url?: string
    portfolio_url?: string
    order: number
}

export interface WBCompany extends StrapiEntity {
    logo: StrapiImage
    company_name: string
    company_website?: string
}

export interface WBProject extends WBProjectList {
    gallery: StrapiImage[]
}

export const fullUrl = (url: string) => {
    return API_URL + url
}

class ApiClinet {

    public async get<T>(path: string, params: Object): Promise<Res<T>> {
        const query = QueryString.stringify(params, { encodeValuesOnly: true })
        const url = `${API_URL}/api/${path}?${query}`
        console.log("GET: " + path + "\n" + url)
        const res = await fetch(url, { method: "GET" })
        const json: Res<T> = await res.json();
        return json;
    }

    public async create<B>(path: string, body: B): Promise<Res<any>> {
        const url = `${API_URL}/api/${path}`
        const bodyJson = JSON.stringify(body);

        console.log("POST: " + path + "\n" + url + "\n" + bodyJson)
        const res = await fetch(url, {
            method: "POST",
            mode: 'cors',
            headers: { "Content-Type": "application/json" },
            body: bodyJson
        })
        const json: Res<any> = await res.json();
        return json;
    }

    public async getData<T>(path: string, params: Object): Promise<T | null> {
        const res = await this.get<T>(path, params);
        if (res != null && "data" in res) {
            return res.data
        }
        return null
    }

    public async getCompanies(): Promise<WBCompany[]> {
        return await this.getData<WBCompany[]>("coustomers", {
            filters: {
                show_in_trusted_us: {
                    $eq: true
                }
            },
            populate: [
                "logo",
            ]
        }).then(data => data ?? [])
    }

    public async getTeams(lang: string): Promise<WBTeam[]> {
        return await this.getData<WBTeam[]>("wb-teams", {
            filters: {
                locale: {
                    $eq: lang
                },
                visible: {
                    $eq: true
                }
            },
            order: ["order:asc"],
            populate: [
                "profile_image",
            ]
        })
            .then(data => data ?? [])
    }

    public async getProject(slug: string, lang: string): Promise<WBProject | null> {
        return await this.getData<WBProject[]>("projects", {
            filters: {
                slug: {
                    $eq: slug
                },
                locale: {
                    $eq: lang
                }
            },
            populate: [
                "thumbnail_image",
                "gallery",
                "customer",
                "categories"
            ]
        })
            .then(data => data ?? [])
            .then(data => data.length == 1 ? data[0] : null)
    }

    public async getProjects(lang: string): Promise<WBProjectList[]> {
        return await this.getData<WBProjectList[]>("projects", {
            sort: ['id:desc'],
            filters: {
                locale: {
                    $eq: lang
                }
            },
            populate: [
                "thumbnail_image",
                "customer",
                "categories"
            ]
        }).then(data => data ?? [])
    }

}

export const apiClient = new ApiClinet()