


















































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class ServicesSection extends Vue {
  //
  mounted() {
    // ok
  }
}
