import globals from '../gloabls'
import { apiClient } from './Api';

export interface SmtpMessage {
    from: string,
    subject: string,
    body: string,
    name?: string | any
}

export default class SmtpSender {

    static async sendSmtp(message: SmtpMessage) {
        await apiClient.create("wb-contact-forms", {
            data: {
                name: message.name,
                email: message.from,
                subject: message.subject,
                message: message.body
            }
        });
    }

    static async openMailBox(message: SmtpMessage) {
        await new Promise(r => setTimeout(r, 1000));
        const url = `mailto:${globals.sendToEmail}?subject=${message.subject}&body=${message.body}&from=${message.from}&name=${message.name}`;
        // console.log(url)
        window.open(url, '_self')
    }

    static async openMailBoxEmpty() {
        const url = `mailto:${globals.sendToEmail}`;
        window.open(url, '_self')
    }

    static async openCallTo(number: string) {
        window.open(`tel:${number}`, '_self');
    }
}