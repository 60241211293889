export class Util {

  private static readonly specialCharacters = /[!@#$%^&*()_+\=\[\]{};:"\\|,.<>\/?]|^(.{1}-.{1})|\s-/
  private static readonly ytRecognizeIdFromUrl = /^.*[v,V]=([a-zA-Z0-9_-]{1,20})&?.*$/

  private static readonly firstApostrophe = /^[']/;
  private static readonly spaceAndApostrophe = /\s[']/;

  public static async runAfter<T>(func: () => T, time: number): Promise<T> {
    await this.wait(time);
    return func();
  }

  public static async wait(time: number): Promise<Util> {
    await new Promise(resolve => setTimeout(resolve, time));
    return this;
  }

  public static getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  public static getErrorStatus(error: any): number {

    // axios
    if (error && error.response?.status && error.message) {
      // console.info(error.response?.status, error.message);
      return error.response?.status
    }


    // angular
    if (error && error.status && error.message) {
      // console.info(error.status, error.message);
      return error.status
    }

    // internal
    if (error instanceof Error) {
      console.warn(this.getErrorMessage(error));
      return 600;
    }

    return 600;
  }

  public static getErrorMessage(error: any, optinalErrorMessage?: string): string {

    // axios
    const errRes = error.response?.data;
    if (errRes) {
      return errRes.message ?? errRes[0]?.defaultMessage ?? 'Api Error';
    }

    // angular
    if (error.error) {
      return error.error.message ?? error.error[0]?.defaultMessage ?? 'Api Error';
    }

    if (error instanceof Error) {
      return error.message ?? optinalErrorMessage ?? "Application Error";
    }

    return "Unknown Error"
  }

  static removeSpecialCharacters(word: String) {
    return word.split(Util.specialCharacters).join('').toLocaleLowerCase();
  }

  static recognizeDomain(domainName: string, url: string): boolean {
    const regexp = new RegExp(`(https|http):\/\/(.*.|)(${domainName}).(com|pl|org).*`);

    return !!url?.match(regexp) ?? false;
  }

  static recongizeYtId(url: string): string | null {
    if (url && Util.recognizeDomain('youtube', url)) {
      let match = url.match(Util.ytRecognizeIdFromUrl);
      const gropus = match?.groups ?? match ?? {}
      // @ts-ignore
      return gropus[1];
    }

    return null;
  }

  static toNumber(string: string) {
    return Number(string?.replace(/\D/g, ''));
  }

  static isBlank(str: string): boolean {
    return (!str || /^\s*$/.test(str));
  }

  static toJson(data: any): string | null {
    return data ? JSON.stringify(data) : null;
  }

  static toJsonOrAny(data: any): string | any {
    try {
      return data ? JSON.stringify(data) : data;
    } catch (e) {
      data
    }
  }

  static toObject<T>(json: string | null | undefined): T | null {
    try {
      return json ? JSON.parse(json) : null;
    } catch (e) {
      console.warn('toObject: json parse fail', e);
      return null;
    }
  }

  static randomUniqueNumbers(from: number, to: number, qty: number): number[] {
    const randomNumbersSet = new Set<number>(Util.indexArray(qty * 3).map(() => Util.getRandomInt(from, to)))
    return Array.from(randomNumbersSet.values()).slice(0, qty);
  }

  static indexArray(lenght: number): number[] {
    return new Array(lenght).fill(0).map((val, idx) => idx)
  }

  static shuffleArray<T>(array: Array<T>): Array<T> {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  static uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static cleanNulls<T extends object>(object: T): T {
    for (let propName in object) {
      if (object[propName] === null || object[propName] === undefined) {
        delete object[propName];
      }
    }
    return object
  }

  static toTimeString(time: number): string {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor((time % 3600) % 60);
    const minutesString = (minutes < 10) ? '0' + minutes : minutes;
    const secondsString = (seconds < 10) ? '0' + seconds : seconds;
    return `${hours}:${minutesString}:${secondsString}`;
  }

  static breakEmail(email: string, lenght = 28): string {
    if (!email) return "";
    return (email.length >= 26) ? email.replace("@", "\n@") : email;
  }

  static formatDescriptionWithHtml(text: string): string {
    if (!text) return "";

    text = text.replace(/<br>/gi, "\n");
    text = text.replace(/<p.*>/gi, "\n");
    // text = text.replace(/<a.*href="(.*?)".*>(.*?)<\/a>/gi, " $2 (Link->$1) ");
    text = text.replace(/<a.*href='(.*?)'.*>(.*?)<\/a>/gi, "$1");
    text = text.replace(/<(?:.|\s)*?>/g, "");
    return text;
  }

}
