



















































































import translationsService, {
  Lang,
  TranslationsService,
} from "@/services/Translations";
import { Component, Vue } from "vue-property-decorator";
import { onAll, onscroll } from "../templateMain";
import { interfaceService } from "../services/Interface";

@Component
export default class Header extends Vue {
  trans: TranslationsService = translationsService;

  setLang(lang: Lang, e: Event) {
    e?.preventDefault();
    console.log("lang", lang);

    this.trans.setLang(lang);
  }

  get nav(): HTMLElement {
    return this.$refs.navbar as HTMLElement;
  }

  get navtoggle(): HTMLElement {
    return this.$refs.navtoggle as HTMLElement;
  }

  get header(): HTMLElement {
    return this.$refs.header as HTMLElement;
  }

  mounted() {
    this.dropDownHandler();
    this.topHeaderStateHandler();
  }

  to(section: string) {
    if (this.nav.classList.contains("navbar-mobile")) {
      this.nav.classList.remove("navbar-mobile");
      this.navtoggle.classList.remove("bi-x");
    }

    interfaceService.toHomeSection(section);
  }

  dropDownHandler() {
    onAll("click", ".navbar .dropdown > a", (e) => {
      if (this.nav.classList.contains("navbar-mobile")) {
        e.preventDefault();
        const target = e.target as HTMLElement;
        target.nextElementSibling?.classList.toggle("dropdown-active");
      }
    });
  }

  topHeaderStateHandler() {
    const headerScrolled = () => {
      if (window.scrollY > 40) {
        this.header.classList.add("header-scrolled");
      } else {
        this.header.classList.remove("header-scrolled");
      }
    };
    onscroll(document, () => headerScrolled());
    headerScrolled()
  }

  mobileNavBarToggle() {
    this.nav.classList.toggle("navbar-mobile");
    this.navtoggle.classList.toggle("bi-x");
  }
}
