

































import { interfaceService } from "@/services/Interface";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class HeroSection extends Vue {
  //
  mounted() {
    // ok
  }

  toServices(){
    interfaceService.toHomeSection('services')
  }
}
