














import { Component, Vue, Prop } from "vue-property-decorator";
import { interfaceService } from "../services/Interface";

@Component
export default class CountsSection extends Vue {
  @Prop() header: string;

  mounted() {
    // ok
  }

  back() {
    interfaceService.back();
  }
}
