




































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class CountsSection extends Vue {
  //
  mounted() {
    // ok
  }
}
