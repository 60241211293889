





















import { Subscription } from "rxjs"
import { apiStore, CompanyItem } from "@/services/ApiStore";
import { Util } from "@/services/Util";
import Swiper from "swiper";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ClientsSection extends Vue {
  sub: Subscription;
  clients: CompanyItem[] = []

  mounted() {
    apiStore.companies$.subscribe(list => {
      this.clients = list;
      this.initSlider();
    })
  }

  beforeUnmount() {
    this.sub?.unsubscribe();
  }

  async initSlider() {
    await Util.wait(200);
    new Swiper(".clients-slider", {
      centeredSlides: false,
      speed: 400,
      // only if more than 3 customers
      loop: false,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 40,
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 60,
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 80,
        },
        992: {
          slidesPerView: 6,
          spaceBetween: 120,
        },
      },
    });
  }
}
