/**
* Template Name: FlexStart - v1.2.0
* Template URL: https://bootstrapmade.com/flexstart-bootstrap-startup-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/


export const select = <T extends HTMLElement>(el: string): T => {
    el = el?.trim() ?? "";
    try{
        return document.querySelector(el) as T
    }catch(e){
        return null;
    }
}

export const selectAll = <T extends HTMLElement>(el: string): T[] => {
    el = el.trim()
    return [...document.querySelectorAll(el)] as T[]
}

/**
 * Easy event listener function
 */
 export const on = (type: string, el: string, listener: (e: Event, item: HTMLElement) => void) => {
    const item = select(el);
    item?.addEventListener(type, (e) => listener(e, item))
}
export const onAll = (type: string, el: string, listener: (e: Event, item: HTMLElement[]) => void) => {
    const items = selectAll(el);
    items.forEach(e => e.addEventListener(type, (e) => listener(e, items)))
}

/**
 * Easy on scroll event listener 
 */
 export const onscroll = (el: Element | Document, listener: (e: Event) => any) => {
    el.addEventListener('scroll', listener)
}

/**
 * Navbar links active state on scroll
 */
let navbarlinks = selectAll<HTMLAnchorElement>('#navbar .scrollto');
const navbarlinksActive = () => {
    let position = window.scrollY + 200
    navbarlinks.forEach(navbarlink => {
        if (!navbarlink.hash) return

        const section = select(navbarlink.hash) as HTMLSelectElement;
        if (!section) return
        if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
            navbarlink.classList.add('active')
        } else {
            navbarlink.classList.remove('active')
        }
    })
}


window.addEventListener('load', navbarlinksActive)
onscroll(document, navbarlinksActive)

/**
 * Scrolls to an element with header offset
 */
export const scrollto = (el: string) => {
    const header = select('#header') as HTMLElement;
    let offset = header.offsetHeight

    console.log('scroll to ', el)

    if (!header.classList.contains('header-scrolled')) {
        offset -= 10
    }

    const element = select(el) as HTMLElement;
    let elementPos = element?.offsetTop
    window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth'
    })
}
