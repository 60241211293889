






















































































































import { Component, Vue } from "vue-property-decorator";

import globals from "../gloabls";
import TranslationsService from "../services/Translations";
import { interfaceService } from "../services/Interface";

@Component
export default class ValuesSection extends Vue {
  g = globals;
  services: { name: string; desc: string }[] = [];

  mounted() {
    TranslationsService.selsectedLanguage$.subscribe(() => {
      this.services = TranslationsService.getMessages().v.services.list;
    });
  }

  to(section: string) {
    interfaceService.toHomeSection(section);
  }
}
