







































































import translationsService, { FAQItem } from "@/services/Translations";
import { Component, Vue } from "vue-property-decorator";
import { Subscription } from "rxjs";

@Component
export default class FAQSection extends Vue {
  sub: Subscription;
  questions1: FAQItem[] = [];
  questions2: FAQItem[] = [];

  mounted() {
    this.sub = translationsService.selsectedLanguage$.subscribe(() => {
      this.questions1 = translationsService.getMessages().v.faq.questions1;
      this.questions2 = translationsService.getMessages().v.faq.questions2;
    });
  }

  beforeUnmount() {
    this.sub?.unsubscribe();
  }
}
