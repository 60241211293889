import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
// import './templateMain.ts'

import Header from './components/Header.vue';
import HeroSection from './components/HeroSection.vue';
import AboutSection from './components/AboutSection.vue';
import ValuesSection from './components/ValuesSection.vue';
import CountsSection from './components/CountsSection.vue';
import FeaturesSection from './components/FeaturesSection.vue';
import ServicesSection from './components/ServicesSection.vue';
import PricingSection from './components/PricingSection.vue';
import FAQSection from './components/FAQSection.vue';
import PortfolioSection from './components/PortfolioSection.vue';
import TestimonialsSection from './components/TestimonialsSection.vue';
import TeamSection from './components/TeamSection.vue';
import ClientsSection from './components/ClientsSection.vue';
import BlogSection from './components/BlogSection.vue';
import ContactSection from './components/ContactSection.vue';
import Breadcrumbs from './components/Breadcrumbs.vue';
import PortfolioDetails from './components/PortfolioDetails.vue';
import Footer from './components/Footer.vue';
import VueMeta from 'vue-meta'
import TranslationsService from './services/Translations';
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import VueToast from 'vue-toast-notification';
import { BootstrapVueIcons } from 'bootstrap-vue'

Swiper.use([Navigation, Pagination, Autoplay]);
Vue.config.productionTip = false
Vue.use(VueToast, {
  position: 'bottom-left'
});
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueMeta)

import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
Vue.use(BootstrapVueIcons)

import 'remixicon/fonts/remixicon.css'

// components
Vue.component('Header', Header)
Vue.component('hero-section', HeroSection)
Vue.component('about-section', AboutSection)
Vue.component('values-section', ValuesSection)
Vue.component('counts-section', CountsSection)
Vue.component('features-section', FeaturesSection)
Vue.component('services-section', ServicesSection)
Vue.component('pricing-section', PricingSection)
Vue.component('faq-section', FAQSection)
Vue.component('portfolio-section', PortfolioSection)
Vue.component('testimonials-section', TestimonialsSection)
Vue.component('team-section', TeamSection)
Vue.component('clients-section', ClientsSection)
Vue.component('blog-section', BlogSection)
Vue.component('contact-section', ContactSection)
Vue.component('Breadcrumbs', Breadcrumbs)
Vue.component('PortfolioDetails', PortfolioDetails)
Vue.component('Footer', Footer)



new Vue({
  i18n: TranslationsService.i18n,
  router,
  render: h => h(App)
}).$mount('#app')

