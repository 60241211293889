











































import { apiStore } from "@/services/ApiStore";
import { TeamMember } from "@/services/Translations";
import { Component, Vue } from "vue-property-decorator";
import { Subscription } from "rxjs"

@Component
export default class TeamSection extends Vue {
  sub: Subscription;
  members: TeamMember[] = [];

  mounted() {
    apiStore.team$.subscribe(list => this.members = list);
  }

  beforeUnmount() {
    this.sub?.unsubscribe();
  }
}
