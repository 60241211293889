













































import {
  PortfolioProject,
} from "@/services/Translations";
import { Subscription } from "rxjs"
import { Component, Vue } from "vue-property-decorator";
import { select, onAll, scrollto } from '../templateMain'
import Isotope from 'isotope-layout';
import { Util } from "../services/Util"
import { apiStore } from "@/services/ApiStore";

@Component
export default class FAQSection extends Vue {
  projects: PortfolioProject[] = [];
  categories: string[] = []

  subProjects: Subscription;
  subCategoreis: Subscription;

  mounted() {
    this.subProjects?.unsubscribe();
    this.subCategoreis?.unsubscribe();
    apiStore.projects$.subscribe(list => {
      this.projects = list
      this.initFilters();
    })
    apiStore.categories$.subscribe(list => this.categories = list)
  }

  beforeUnmount() {
    this.subProjects?.unsubscribe();
    this.subCategoreis?.unsubscribe();
  }

  async initFilters() {
    await Util.wait(150);
    const portfolioContainer = select('.portfolio-container');
    if (portfolioContainer) {
      const portfolioIsotope = new Isotope(portfolioContainer, {
        itemSelector: '.portfolio-item',
        layoutMode: 'fitRows'
      });

      onAll('click', '#portfolio-flters li', (e, filters) => {
        e.preventDefault();
        const target = e.target as HTMLElement;
        filters.forEach(function (el) {
          el.classList.remove('filter-active');
        });
        target.classList.add('filter-active');

        portfolioIsotope.arrange({
          filter: target.getAttribute('data-filter')
        });
      });
    }
  }

  goToPage(project: PortfolioProject) {
    if (!project.pagerUrl) return;
    window.open(project.pagerUrl);
  }

  async openDetails(project: PortfolioProject) {
    apiStore.selectProject(project)
    const path = `/project-details/${project.id}`;
    if (this.$route.path !== path) await this.$router.push({ path: path });
    await Util.wait(100);
    scrollto("#portfolio-details");
  }
}
