import { apiClient, fullUrl, WBProject, WBProjectList, WBTeam } from "./Api"
import { ImageFormat, StrapiImage } from "./strapi.common";
import translationsService, { PortfolioProject, TeamMember } from "./Translations"
import { BehaviorSubject } from 'rxjs';

export function getFormat(image: StrapiImage, format: keyof typeof image.formats): ImageFormat {
    if (image.formats == null) {
        return {
            url: image.url,
            width: image.width,
            height: image.height,
        } as ImageFormat
    }

    return image.formats[format] ?? image.formats["medium"] ?? image.formats["small"] ?? image.formats["thumbnail"]
}

export function getFormatUrl(image: StrapiImage, format: keyof typeof image.formats): string {
    return fullUrl(getFormat(image, format).url)
}

const toPortfolioProject = (apiProject: WBProject | WBProjectList): PortfolioProject => {
    const projectCategories = apiProject.categories?.sort((a, b) => a.order - b.order)?.map(c => c.name) ?? []
    const mainImage = getFormatUrl(apiProject.thumbnail_image, 'medium');
    const gallery = (apiProject.gallery ?? []).map(image => getFormatUrl(image, 'medium'))
    return {
        id: apiProject.slug,
        name: apiProject.customer.company_name,
        customer: apiProject.customer.company_name,
        categories: projectCategories,
        pagerUrl: apiProject.page_url,
        releaseDate: apiProject.realization_date,
        mainImage: mainImage,
        shortDescription: apiProject.short_description,
        detailsDescription: apiProject.description,
        images: [mainImage, ...gallery],
        full: gallery.length > 0,
        locale: apiProject.locale
    }
}

const toTeamMember = (team: WBTeam): TeamMember => {
    const imageUrl = getFormatUrl(team.profile_image, 'medium');
    return {
        fullname: team.full_name,
        title: team.title,
        description: "",
        img: imageUrl,
        links: {
            linkedIn: team.linkedIn_url,
            webpage: team.portfolio_url
        }
    }
}

export interface CompanyItem {
    image_url: string,
    page_url?: string,
    name: string
}

class ApiStore {

    readonly projectSelected$: BehaviorSubject<PortfolioProject | null> = new BehaviorSubject(null);

    readonly projects$: BehaviorSubject<PortfolioProject[]> = new BehaviorSubject([]);
    readonly categories$: BehaviorSubject<string[]> = new BehaviorSubject([]);

    readonly team$: BehaviorSubject<TeamMember[]> = new BehaviorSubject([]);
    readonly companies$: BehaviorSubject<CompanyItem[]> = new BehaviorSubject([]);

    projects: PortfolioProject[] = []

    public async init() {
        translationsService.selsectedLanguage$.subscribe(lang => {
            this.loadProjects(lang);
            this.loadTeam(lang)
            this.loadProject(this.projectSelected$.value?.id);
            this.loadCompanies();
        })
    }

    public async loadCompanies() {
        const companies: CompanyItem[] = (await apiClient.getCompanies()).map(c => ({
            image_url: getFormatUrl(c.logo, "small"),
            page_url: c.company_website,
            name: c.company_name
        }))
        this.companies$.next(companies)
    }

    public async loadTeam(lang: string) {
        const teams = (await apiClient.getTeams(lang)).map(t => toTeamMember(t))
        this.team$.next(teams)
    }

    public async loadProjects(lang: string) {
        const projects = await apiClient.getProjects(lang);
        const categoriesSet = new Set<string>()
        const portfolioProjects = projects.map(apiProject => {
            const p = toPortfolioProject(apiProject)
            p.categories.forEach(cat => categoriesSet.add(cat))
            return p
        })
        this.projects$.next(portfolioProjects)
        this.categories$.next(Array.from(categoriesSet.values()))
    }

    public selectProject(project: PortfolioProject) {
        this.projectSelected$.next(project)
    }

    public async loadProject(projectSlug: string): Promise<PortfolioProject> {
        if (projectSlug == null) return null;

        const currentLang = translationsService.selsectedLanguage$.value;
        const store = this.projectSelected$.value;
        if (store == null || store.id != projectSlug || !store.full || store.locale != currentLang) {
            const project = await apiClient.getProject(projectSlug, translationsService.selsectedLanguage$.value)
            const p = toPortfolioProject(project);
            this.projectSelected$.next(p)
            return p;
        }
        return store;
    }


}

export const apiStore = new ApiStore()