















import { Component, Vue } from "vue-property-decorator";
import { select, onscroll } from "./templateMain";
import { Util } from "./services/Util";
import AOS from "aos";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import translationsService from "./services/Translations";
import { apiStore } from "./services/ApiStore";

@Component({
  metaInfo: {
    title: "Wildbirds.studio ",
    titleTemplate: "%s | Software for your needs",
  },
})
export default class App extends Vue {
  private translationSub: Subscription;
  private js: HTMLScriptElement;

  mounted() {
    this.handleBackToTop();
    this.initAnimateOnScroll();
    apiStore.init();
    this.translationSub = translationsService.selsectedLanguage$
      .pipe(distinctUntilChanged())
      .subscribe()
      // .subscribe(() => this.initMessanger());
  }

  beforeUnmount() {
    this.translationSub?.unsubscribe();
  }

  handleBackToTop() {
    const backtotop = select(".back-to-top");
    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        backtotop.classList.add("active");
      } else {
        backtotop.classList.remove("active");
      }
    };
    window.addEventListener("load", toggleBacktotop);
    onscroll(document, toggleBacktotop);
  }

  async initAnimateOnScroll() {
    await Util.wait(200);
    AOS.init({
      duration: 700,
      easing: "ease-in-out",
      once: false,
      mirror: false,
    });
  }

  async initMessanger() {

    const id = "facebook-jssdk";
    const locationMap = {
      pl: "pl_PL",
      en: "en_US",
    };

    // clear
    document.getElementById(id)?.remove();
    document.querySelector(".fb_dialog")?.remove();
    this.js?.remove();
    window.FB = null;
    window.fbAsyncInit = null;
    await Util.wait(50);

    // init
    const lang = translationsService.getLang();
    const fjs = document.getElementsByTagName("script")[0];
    window.fbAsyncInit = () => {
      window.FB.init({
        xfbml: true,
        version: "v10.0",
      });
    };

    this.js = document.createElement("script");
    this.js.id = id;
    this.js.src = `https://connect.facebook.net/${locationMap[lang]}/sdk/xfbml.customerchat.js`;
    fjs.parentNode.insertBefore(this.js, fjs);
  }
}
