

























































import { Component, Vue } from "vue-property-decorator";

@Component
export default class BlogSection extends Vue {
  //
  mounted() {
    // ok
  }
}
