import router from '../router/index'
import { select, scrollto } from "../templateMain";

export class InterfaceService {

    toHomeSection(selector: string) {
        const path = `/home/${selector}`;
        if (router.currentRoute.path !== path) {
            router.push({ path: path });
        } else if (select(selector)) {
            this.toSection(selector);
        }
    }

    toSection(section: string) {
        const selector = section && section.length > 1 ? `#${section}` : `#home`;
        scrollto(selector);
        setTimeout(() => scrollto(selector), 200)
    }

    to(path: string) {
        if (router.currentRoute.path !== path) {
            router.push({ path: path });
        }
    }

    back() {
        router.back();
    }

}

export const interfaceService = new InterfaceService();