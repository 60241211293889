























import { Component, Vue, Watch } from "vue-property-decorator";
import { scrollto } from "../templateMain";
import { interfaceService } from "@/services/Interface";

@Component({})
export default class Home extends Vue {
  mounted() {
    this.scroll();
  }

  @Watch('$route')
  scroll() {
    interfaceService.toSection(this.$route.params.section)
  }
}
